import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  MenuItem,
} from '@material-ui/core'
import { FreeDatabase, StandardDatabase } from '../actions'
import {
  extractError,
  extractErrorValue,
} from '../../../../shared/errorExtractors'
import ErrorComponent from '../../../../shared/components/ErrorComponent'
import CancelButton from '../../../../shared/components/CancelButton'
import SubmitButton from '../../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../../shared/hooks/useMaybeNotify'
import { choices, composeValidators, required } from '../../../../utils/validators'
import {
  renderSelect,
} from '../../../../shared/components/formFields'

interface Props {
  appId: string
  database: StandardDatabase | FreeDatabase
  onSuccess: () => void
  onCancel: () => void
}
interface FormData {
  version: string
}
const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: 600,
  },
  size: {
    marginRight: theme.spacing(3),
  },
  // duplicated in Field.tsx
  label: {
    color: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2, 0, 1),
  },
  text: {
    padding: theme.spacing(2, 0, 0),
    margin: 0,
  },
}))

const pgVersions = ['POSTGRES_9_6', 'POSTGRES_10', 'POSTGRES_11', 'POSTGRES_12', 'POSTGRES_13', 'POSTGRES_14', 'POSTGRES_15', 'POSTGRES_16']
const renderVersion = renderSelect
const validateVersion = composeValidators([
  required(),
  choices(pgVersions, 'Invalid version'),
])

const UpgradeDialog: FunctionComponent<Props> = (props) => {
  const { appId, database, onCancel, onSuccess } = props
  const classes = useStyles(props)
  const refresh = useRefresh()
  const notify = useMaybeNotify()
  const [mutate] = useMutation()

  // value is used to space them apart
  // label is the label, but later we
  // use the label as the value to submit

  const submit = ({ version }: FormData) => {
    const upgradeValues = {
      ...database,
      version: version,
      appId,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'UPGRADE',
          resource: 'databases',
          payload: {
            id: database.id,
            data: upgradeValues,
            previousData: database,
          },
        },
        {
          onSuccess: () => {
            notify('Database upgrading')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, [
                '',
                'app_name',
                'app_id',
                'database_id',
              ]),
              version: extractErrorValue(errors, 'version'),
            })
          },
        }
      )
    })
  }

  const availableUpgrades = database.tier === "STANDARD" ? pgVersions.filter((v) => pgVersions.indexOf(database.version) <= pgVersions.indexOf(v)) : []

  return (
    <Form
      onSubmit={submit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          submitError,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          pristine,
        } = formRenderProps
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle id="form-dialog-title">Upgrade Database</DialogTitle>
            {database.tier === 'STANDARD' ? (
              <>
                {submitError && (
                  <DialogContent>
                    <FormHelperText error>
                      <ErrorComponent>{submitError}</ErrorComponent>
                    </FormHelperText>
                  </DialogContent>
                )}
                <DialogContent>
                  <Field
                    validate={validateVersion}
                    component={renderVersion}
                    name="version"
                    label="Version"
                    initialValue={database.version}
                  >
                    {availableUpgrades.map((version) => (
                      <MenuItem 
                        key={version} 
                        value={version} 
                        disabled={version === database.version}
                      >
                        {version === database.version ? `${version} (current)` : version}
                      </MenuItem>
                    ))}
                  </Field>
                </DialogContent>
                <DialogActions>
                  <CancelButton onClick={onCancel} />
                  <SubmitButton
                    {...{
                      hasValidationErrors,
                      hasSubmitErrors,
                      modifiedSinceLastSubmit,
                      pristine,
                      submitting,
                    }}
                    label="Upgrade"
                    variant="contained"
                    size="small"
                  />
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogContentText className={classes.text}>
                    Sorry, free tier databases can not be upgraded. <br />
                    Please create a standard tier database.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <CancelButton onClick={onCancel}>Dismiss</CancelButton>
                </DialogActions>
              </>
            )}
          </form>
        )
      }}
    />
  )
}

export default UpgradeDialog
